@import '../../scss/_variables.scss';
@import '../../scss/_fonts.scss';

.Login {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;

  .Window {
    background: $white;
    padding: 60px;
    max-width: 360px;

    h2 {
      font-size: 28px;
      text-align: center;
      margin-bottom: 45px;
      font-weight: 500;
    }

    input {
      border: 0;
      border-bottom: 4px solid $black;
      padding: 6px 0;
      background: $white;
      outline: 0;
      margin-bottom: 20px;
      width: 100%;
      text-align: left;
      font-size: 20px;
      font-weight: 500;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }

    button {
      width: 100%;
      text-align: center;
      padding: 25px;
      color: $white;
      background: $black;
      font-size: 20px;
      font-weight: 500;
      font-family: $display;
    }
  }
}
