@import '../../scss/_variables.scss';
@import '../../scss/_fonts.scss';

.Book {
  position: relative;
  flex-basis: calc(50% - 50px);
  margin: 25px;
  box-sizing: border-box;
  padding: 30px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: $x;

  h3 {
    font-size: 20px;
    text-align: left;
  }

  // &:before {
  //   content: '';
  //   display: block;
  //   padding-top: 100%;
  // }

  &:hover {
    transform: scale(1.05);
    transition: $x;
  }

  img {
    margin: 15%;
    color: $black;
    pointer-events: none;
  }

  .Book-Info {
    position: absolute;
    left: 30px;
    bottom: 30px;
    pointer-events: none;

    h3:nth-of-type(1) {
      margin: 0 0 8px;
    }

    h3:nth-of-type(2) {
      margin: 0;
      font-weight: 400;
    }
  }

  .Book-More {
    position: absolute;
    bottom: 30px;
    right: 30px;
    text-align: right;

    h3 {
      font-weight: 400;
      margin: 0;
    }
  }

  // .content {
  //   position: absolute;
  //   top: 0; left: 0;
  //   height: 100%;
  //   width: 100%;
  // }
}

.Modal > div {
  flex-basis: 50%;
  padding: 150px;
}

.Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  background: $white;
  justify-content: center;
  align-items: center;

  .Title {
    opacity: .04;
    margin-top: -12px;
  }
}

.Description {
  z-index: 120;

  .Back {
    position: absolute;
    top: 75px;
    left: 150px;
    font-size: 28px;
    opacity: .5;
    border: 0;
    outline: 0;
    font-weight: 500;
    font-family: $display;
    cursor: pointer;
    transition: $x;

    &:hover {
      opacity: 1;
      transition: $x;
    }
  }

  h4 {
    font-size: 20px;
    display: inline-block;
    margin-bottom: 30px;

    &:nth-of-type(1) {
      margin-right: 30px;
    }

    &:nth-of-type(2) {
      opacity: .5;
    }
  }

  p {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 50px;
  }

  .Button-Holder {
    display: flex;
    justify-content: space-between;

    .Button {
      flex-basis: 48%;
      display: inline-block;
      font-size: 20px;
      font-family: $display;
      font-weight: 500;
      padding: 30px;
      background: $black;
      text-decoration: none;
      text-align: center;

      &:nth-of-type(1) {
        color: $white;
      }

      &:nth-of-type(2) {
        color: $black;
      }
    }
  }
}

.Cover {
  text-align: center;
  position: relative;

  .Texture {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    color: $black;
  }

  .Cover-Photo {
    margin-bottom: 20px;
  }

  .Book-Title {
    background: $black;
    padding: 30px 50px;
    margin-bottom: 25px;
    display: block;

    h3 {
      font-size: 28px;

      &:nth-of-type(1) {
        font-weight: 500;
        margin-bottom: 8px;
      }

      &:nth-of-type(2) {
        font-weight: 400;
      }
    }
  }

  .Book-Details {
    font-size: 20px;
    font-weight: 400;

    span {
      padding: 0 8px;
    }
  }
}
