// Global Colours
$white: #fff;
$black: #242424;
$grey: #efefef;


// Book Colours
$pink: #FFC9EA;
$blue: #CEE1FF;
$yellow: #FFE6BF;
$green: #D5FCE5;
$brown: #E3D2CB;

// Fonts
$title: "TitlingGothicFB Wide";
$display: "TitlingGothicFB Normal";
$body: "Apercu";
$code: "Apercu Mono";


// other
$x: .3s ease;
