@import '../../scss/_variables.scss';
@import '../../scss/_fonts.scss';

.Menu {
  width: 75px;
  height: 75px;
  background: $black;
  position: absolute;
  top: 15px;
  right: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px;

  p {
    font-size: 40px;
    font-family: $display;
    font-weight: 500;
    text-transform: uppercase;
    color: $white;
  }
}
