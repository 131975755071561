@font-face {
  font-family: 'TitlingGothicFB Normal';
  src: url('../fonts/TitlingGothicFBNor-Reg.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TitlingGothicFB Normal';
  src: url('../fonts/TitlingGothicFBNor-Stand.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TitlingGothicFB Normal';
  src: url('../fonts/TitlingGothicFBNor-Med.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/Apercu-Reg.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/Apercu-RegItalic.otf') format('otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/Apercu-Bold.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('../fonts/Apercu-BoldItalic.otf') format('otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Apercu Mono';
  src: url('../fonts/Apercu-Mono.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}
