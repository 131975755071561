@import '../../scss/_variables.scss';
@import '../../scss/_fonts.scss';

.Title {
  transform: translate3d(0, 0, 0);
  background: transparent url(../../img/logo.svg) no-repeat bottom left;
  font-size: 0px;
  color: transparent;
  min-height: 528px;
  width: 100%;
  display: block;
  // width: 10vw;
  height: 107vh;
  margin-left: -5px;
  margin-top: -52px;
  opacity: .08;
  position: fixed;
  z-index: 0;
  pointer-events: none;
}
